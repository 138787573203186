import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import { saveAndSubmitTransactionAction } from "../../../store/actions/OMS/Actions";

import { ThemeContext } from "../../../context/ThemeContext";

const NewTransaction = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [image, setImage] = useState(
    props.reduxCurrentState.one.myAccount.image
  );

  const reduxUsername = useSelector((state) => state.one.myAccount.username);
  const [isEditUsername, setIsEditUsername] = useState(false);
  const [localUsername, setLocalUsername] = useState(reduxUsername);

  const [isLoading, setIsLoading] = useState(false);

  const [fullName, setFullname] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [gender, setGender] = useState("Male");

  const [state, setState] = useState("Education Completed");

  const [yop, setYop] = useState("2024");
  const [mainDegree, setMainDegree] = useState("B.Tech");
  const [specialization, setSpecialization] = useState("Computer Science");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [technologyInterested, setTechnologyInterested] = useState(
    "Data Science & AI"
  );

  const [txnCategory, setTxnCategory] = useState("Expense");
  const [txnSubCategory, setTxnSubCategory] = useState("Utilities");
  const [txnType, setTxnType] = useState("Debit");
  const [nextAction, setNextAction] = useState("Need to Send Content");

  const [upiID, setUpiID] = useState(
    props.reduxCurrentState.one.myAccount.upi_id
  );

  const [isEdited, setIsEdited] = useState(false);

  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    // dispatch(getMyAccountDetails(props.reduxCurrentState.one.webAuthTokenId));
    setLocalUsername(reduxUsername);
  }, [reduxUsername]);

  const handleFullnameChange = (e) => {
    setFullname(e.target.value);
  };

  const handleWhatsappNoChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length <= 10) {
      setWhatsappNo(numericValue);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTechnologyInterested = (event) => {
    setTechnologyInterested(event.target.value);
  };

  const handleTxnType = (event) => {
    setTxnType(event.target.value);
  };

  const handleTxnCategory = (event) => {
    setTxnCategory(event.target.value);
  };

  const handleTxnSubCategory = (event) => {
    setTxnSubCategory(event.target.value);
  };
  const history = useHistory();
  const onPressCancel = (temp) => {
    history.push("/finance");
  };

  const onPressSaveAndSubmit = (temp) => {
    let message = null;

    setIsLoading(true);

    if (!fullName) {
      message = "Please enter valid title";
    } else if (!whatsappNo) {
      message = "Please enter valid amount";
    }

    if (message !== null) {
      swal("Oops", message, "error");
      setIsLoading(false); // Stop loading if there's an error
      return;
    }

    const data = {
      category: txnCategory,
      txnType: txnType,
      title: fullName,
      amount: whatsappNo,
      txnDate: selectedDate,
      subCategory: txnSubCategory,
      technology: technologyInterested
    };

    dispatch(
      saveAndSubmitTransactionAction(props.reduxCurrentState.one.webAuthTokenId, data)
    )
      .then(() => {
        setIsLoading(false);
        history.push("/finance");
      })
      .catch((error) => {
        swal("Error", "Something went wrong. Please try again later.", "error");
        setIsLoading(false);
      });
  };

  // Custom Input Component with Inline Styles
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      className="form-control"
      onClick={onClick}
      ref={ref}
      value={value}
      placeholder="Choose Date"
      style={{
        backgroundColor: "white", // Set background color to white
        color: "black", // Set text color
      }}
    />
  ));

  return (
    <Fragment>
      <div className="row">
        <div className="login-wrapper ">
          <div className="container h-100 ">
            <div className="row h-100 d-flex justify-content-center align-items-center">
              <div className="col-xl-0 col-md-8">
                <div className="sign-in-your">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div style={{ textAlign: "center" }}>
                          <label
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            Transaction Details
                          </label>
                          <hr
                            style={{
                              border: "0.5px solid black",
                              width: "100%",
                            }}
                          />
                        </div>

                        <div>
                          <br></br>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Category</label>
                          <select
                            id="specialization"
                            className="form-control"
                            value={txnCategory}
                            onChange={handleTxnCategory}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Expense">Expense</option>
                            <option value="Enrollment">Enrollment</option>
                            <option value="Project Work">Project Work</option>
                            <option value="Investment">Investment</option>

                            <option value="Other">Other</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Txn Type</label>
                          <select
                            id="specialization"
                            className="form-control"
                            value={txnType}
                            onChange={handleTxnType}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <option value="Debit">Debit</option>
                            <option value="Credit">Credit</option>
                          </select>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>
                            Txn Title <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            placeholder="Eg: Office Rent"
                            onChange={handleFullnameChange}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          />
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>
                            Amount (INR) <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={whatsappNo}
                            onChange={handleWhatsappNoChange}
                            placeholder="Please Enter Txn Amount"
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                            length={10}
                          />
                        </div>

                        <div className="form-group mb-4 col-md-6">
                          <label>Date of Txn</label>

                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            customInput={<CustomInput />}
                          />
                        </div>

                        {txnCategory == "Enrollment" ? (
                          <>
                            <div className="form-group mb-4 col-md-6">
                              <label>Technology</label>
                              <select
                                id="inputState"
                                className="form-control"
                                value={technologyInterested}
                                onChange={handleTechnologyInterested}
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                }}
                              >
                                <option value="Data Science & AI">
                                  Data Science & AI
                                </option>
                                <option value="Data Analytics">
                                  Data Analytics
                                </option>
                                <option value="Generative AI">
                                  Generative AI
                                </option>
                                <option value="Digital Marketing">
                                  Digital Marketing
                                </option>
                                <option value="Cyber Security">
                                  Cyber Security
                                </option>
                                <option value="Blockchain & Crypto">
                                  Blockchain & Crypto
                                </option>
                              </select>
                            </div>
                          </>
                        ) : (
                          <div className="form-group mb-4 col-md-6">
                            <label>Sub-Category</label>
                            <select
                              id="specialization"
                              className="form-control"
                              value={txnSubCategory}
                              onChange={handleTxnSubCategory}
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              <option value="Utilities">Utilities</option>
                              <option value="Offline Marketing">
                                Offline Marketing
                              </option>
                              <option value="Digital Marketing">
                                Digital Marketing
                              </option>

                              <option value="Employees">Employees</option>

                              <option value="Physical Office">Physical Office</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        )}
                      </div>

                      {isLoading ? (
                        <div className="text-center">
                          <div
                            className="spinner-border text-primary text-center"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                onClick={() => onPressCancel(true)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-secondary btn-block"
                                onClick={() => onPressSaveAndSubmit(false)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(NewTransaction);
