import React, { useContext, useState, useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";

import { connect, useDispatch, useSelector } from "react-redux";

import "@react-pdf-viewer/core/lib/styles/index.css";

import { ThemeContext } from "../../../context/ThemeContext";
import { getTransactions } from "../../../store/actions/OMS/Actions";
import FilteringTable from "./Finance/FilteringTable/FilteringTable";

const Finance = (props) => {
  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);
  const transactionData = useSelector((state) => state.two.transactions);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    if (props.reduxCurrentState.one?.webAuthTokenId) {
      dispatch(getTransactions(props.reduxCurrentState.one.webAuthTokenId));
    }
  }, [dispatch, props.reduxCurrentState.one]);

  return (
    <>
      <div className="row">
        <FilteringTable
          {...props} // Spread operator for all properties in `props`
          data={transactionData}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Finance);
