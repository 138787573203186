/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import medal from "../../../images/medal.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (p) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  const [isTrainerToolsExpanded, setIsTrainerToolsExpanded] = useState(false);

  const toggleTrainerTools = () => {
    setIsTrainerToolsExpanded(!isTrainerToolsExpanded);
  };

  const history = useHistory(); // Initialize useHistory hook

  useEffect(() => {
    if (p.currentState.one.role === "Counselor") {
      history.push("/dashboard");
    }

    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    // handleheartBlast.addEventListener('click', heartBlast);
  }, [p.currentState.one.role, history]);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  /// Active menu
  let deshBoard = ["classes"];

  let materials = ["materials"];
  let projectsPaths = ["projects"];
  let playgroundPaths = ["playground"];
  let profilePaths = ["myprofile"];
  let rankingsPaths = ["rankings"];
  let resourcePaths = ["resources"];
  let classPaths = ["classes"];
  let classes = ["classes"];
  let admin = ["register-user", "users", "add-class"];
  let mygifts = ["my-treats"];
  let attendence = ["attendence"];
  let challengesPaths = ["challenges"];
  let assignmentPaths = ["assignments"];
  let instructors = ["instructors"];

  let registerUserPaths = ["register-user"];

  // OMS Paths

  let dashBoard = ["dashboard"];
  let analytics = ["analytics"];
  let finance = ["finance"];
  let saveRecording = ["save-recording"];

  let homeCoun = [""];

  let leadPaths = ["leads"];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          {p.currentState.one.role == "Student" && (
            <>
              <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/classes">
                  <i class="bi bi-play-circle-fill"></i>
                  <span className="nav-text">Classes</span>
                </Link>
              </li>

              <li
                className={`${projectsPaths.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="has-arrow" to="/projects">
                  <i class="bi bi-bar-chart-line-fill"></i>
                  <span className="nav-text">Projects</span>
                </Link>
              </li>

              <li
                className={`${rankingsPaths.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="has-arrow" to="/rankings">
                  <i class="bi bi-people-fill"></i>
                  <span className="nav-text">Rankings</span>
                </Link>
              </li>

              <li
                className={`${
                  challengesPaths.includes(path) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow" to="/challenges">
                  <i class="bi bi-circle-square"></i>
                  <span className="nav-text">Challenges</span>
                </Link>
              </li>

              <li
                className={`${resourcePaths.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="has-arrow" to="/resources">
                  <i class="bi bi-card-image"></i>
                  <span className="nav-text">Resources</span>
                </Link>
              </li>

            
              <li>
                <Link className="has-arrow ai-icon" to="#">
                  <i class="bi bi-briefcase-fill"></i>
                  <span className="nav-text">Job Assistance</span>
                </Link>

                <ul>
                  <li>
                    <Link
                      className={`${
                        path === "resume-building" ? "mm-active" : ""
                      }`}
                      to="/resume-building"
                    >
                      Resume Building
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={`${path === "mock-tests" ? "mm-active" : ""}`}
                      to="/mock-tests"
                    >
                      Mock Tests
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={`${
                        path === "mock-interviews" ? "mm-active" : ""
                      }`}
                      to="/mock-interviews"
                    >
                      Mock Interviews
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          )}


          {p.currentState.one.role == "Counselor" && (
            <>
              <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/dashboard">
                  <i className="bi bi-grid-fill"></i>
                  <span className="nav-text">Home</span>
                </Link>
              </li>

              <li className={`${analytics.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/analytics">
                  <i class="bi bi-bar-chart-fill"></i>
                  <span className="nav-text">Analytics</span>
                </Link>
              </li>

              <li className={`${finance.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/finance">
                  <i class="bi bi-wallet-fill"></i>{" "}
                  <span className="nav-text">Finance</span>
                </Link>
              </li>

              
            </>
          )}

          {p.currentState.one.role == "Trainer" && (
            <>
              <li className={`${saveRecording.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/save-recording">
                  <i className="bi bi-grid-fill"></i>
                  <span className="nav-text">Save Recording</span>
                </Link>
              </li>

              <li
                className={`${
                  registerUserPaths.includes(path) ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow" to="/register-user">
                  <i className="bi bi-pie-chart-fill"></i>
                  <span className="nav-text">Register Student</span>
                </Link>
              </li>
            </>
          )}

        </MM>
      </PerfectScrollbar>
    </div>
  );
};

// export default SideBar;

const mapStateToProps = (state) => {
  return {
    currentState: state,
  };
};

export default connect(mapStateToProps)(SideBar);
