import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./pages/Home";
import Classes from "./pages/Classes";
import Jobs from "./pages/Jobs";

//Courses----
import Attendence from "./pages/Attendence";

import Classes2 from "./pages/Classes2";
import Playground from "./pages/Playground";
import Users from "./pages/Users";

import Profile from "./pages/ProfileNew";
import ProfileTest from "./pages/ProfileTest";
import MyTreats from "./pages/MyTreats"
import Rankings from "./pages/Rankings"
import Challenges from "./pages/Challenges"

/// Pages
import ChangePassword from "./pages/ChangePassword";
import MyAccount from "./pages/MyAccount";
import Login from "./pages/Login";
import RegisterUser from "./pages/RegisterUser";
import AddClass from "./pages/AddClass";
import AddNewClass from "./pages/AddNewClass";

import { ThemeContext } from "../context/ThemeContext";

import Resources from "./pages/Resources";
import MockTests from "./pages/MockTests";

import Assignments from "./pages/Assignments";
import AddAssignment from "./pages/AddAssignment";


import MockInterviews from "./pages/MockInterviews";
import ResumeBuilding from "./pages/ResumeBuilding";

import Chat from "./pages/Chat";

import instructors from "./pages/instructors/Profile";


// import FilteringTable from "./components/FilteringTable/FilteringTable";
import FilteringTable from "../jsx/pages/components/FilteringTable/FilteringTable";
import AddNewLead from "./pages/OMS/AddNewLead";
import Dashboard from "./pages/OMS/Dashboard";
import Analytics from "./pages/OMS/Analytics";
import Finance from "./pages/OMS/Finance";

import NewEnquiry from "./pages/OMS/NewEnquiry";
import NewTransaction from "./pages/OMS/NewTransaction";


const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);

  const userRoutes = [

    { url: "classes", component: Classes },
    { url: "instructors", component: instructors },
    // { url: "classes", component: Classes },
    { url: "projects", component: Classes2 },
    { url: "playground", component: Playground },

    { url: "assignments", component: Assignments },
    { url: "add-assignment", component: AddAssignment },

    { url: "attendence", component: Attendence },
    { url: "my-treats", component: MyTreats },
    { url: "rankings", component: Rankings },
    { url: "resources", component: Resources },
    { url: "challenges", component: Challenges },
    // { url: "chat", component: Chat },

    { url: "mock-tests", component: MockTests },
    { url: "resume-building", component: ResumeBuilding },
    { url: "mock-interviews", component: MockInterviews },

    { url: "my-account", component: MyAccount },
    { url: "change-password", component: ChangePassword },
    { url: "register-user", component: RegisterUser },
    { url: "users", component: Users },
    { url: "add-new-class", component: AddNewClass },
    { url: "save-recording", component: AddClass },


    //OMS

    { url: "add-new-lead", component: AddNewLead },
    { url: "dashboard", component: Dashboard },
    { url: "analytics", component: Analytics },
    { url: "finance", component: Finance },

    
    { url: "new-enquiry", component: NewEnquiry },
    { url: "new-transaction", component: NewTransaction },

    
    // { url: "myprofile", component: ProfileTest },

  ];

  // { url: "materials", component: Materials },
  // { url: "dashboard", component: Home },
  // { url: "jobs", component: Jobs },

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div
          className={`${!pagePath ? "content-body" : ""}`}
          // style={{ minHeight: window.screen.height - 45 }}
        >
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Switch>
              {userRoutes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* {!pagePath && <Footer />} */}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
