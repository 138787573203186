import React, { useContext, useState, useRef, useEffect } from "react";
import { Dropdown, Card, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import Dropzone from "react-dropzone-uploader";
import { ThemeContext } from "../../context/ThemeContext";
import swal from "sweetalert";

import {
  loadingToggleAction,
  registerUserAction,
} from "../../store/actions/AuthActions";

import "react-dropzone-uploader/dist/styles.css";
import Drop from "react-dropzone-uploader";

const AddClass = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [classTitle, setClassTitle] = useState("");
  const [webexLink, setWebexLink] = useState("");
  const [password, setPassword] = useState("");

  let errorsObj = { classTitle: "", webexLink: "", password: "" };

  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();
  const [selectedTrack, setSelectedTrack] = useState("Data Science");
  const [selectedBatch, setSelectedBatch] = useState("Batch 1");

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectedTime, setSelectedTime] = useState("12:00");
  const [isAmSelected, setIsAmSelected] = useState(true);

  const [uploadedFile, setUploadedFile] = useState(null);

  const [runningNotes, setRunningNotes] = useState(true);

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setUploadedFile(file);
    } else if (status === "removed") {
      setUploadedFile(null);
    }
  };

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  const handleAmPmChange = (isAm) => {
    setIsAmSelected(isAm);
  };

  const onClickUploadSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (classTitle === "") {
      errorObj.classTitle = "Class Title is Required";
      error = true;
    }
    
    setErrors(errorObj);
    if (error) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            method: "addclass",
            web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
            class_title: classTitle,
            webex_link: webexLink,
            password: password,
            track: selectedTrack,
            batch: selectedBatch,
            date: selectedDate,
          }),
        }
      );

      const responseJson = await response.json();

      if (!responseJson["error"]) {
        console.log(responseJson);
        swal(
          "Done",
          responseJson["message"] + " " + responseJson["file_name"],
          "success"
        );
        setClassTitle("");
        setWebexLink("");
        setPassword("");
        setIsLoading(false);
      } else {
        const error = responseJson["message"];
        setIsLoading(false);
        swal("Oops", error, "error");
      }
    } catch (error) {
      console.log("request failed", error);
      alert("Backend error, try again or reach out to us.");
    }
  };

  const formattedTime = `${selectedTime}${isAmSelected ? "AM" : "PM"}`;

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSelect = (eventKey) => {
    setSelectedTrack(eventKey);
  };

  const handleSelectBatch = (event) => {
    setSelectedBatch(event.target.value);
  };

  const handleSelectTrack = (event) => {
    setSelectedTrack(event.target.value);
  };

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <>
      {props.reduxCurrentState.one.role == "Trainer" && (
        <div className="row justify-content-center ">
          <div className="col-0 col-md-6">
            <div className="sign-in-your">
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}

              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}

              <form onSubmit={onClickUploadSubmit}>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Class Title</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter title"
                    onChange={(e) => setClassTitle(e.target.value)}
                    value={classTitle}
                  />
                  {errors.classTitle && (
                    <div className="text-danger fs-12">{errors.classTitle}</div>
                  )}
                </div>

               
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Class Date</strong>
                  </label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Choose Date"
                    selected={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>

                <br></br>

                <div className="row">
                  <div className="form-group mb-4 col-md-6">
                    <select
                      id="inputState"
                      className="form-control"
                      value={selectedTrack}
                      onChange={handleSelectTrack}
                    >
                      <option value="Data Science">Data Science</option>
                      <option value="Digital Marketing">Digital Marketing</option>
                      <option value="Cyber Security">Cyber Security</option>

                      <option value="SQL">SQL</option>

                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <select
                      id="inputState"
                      className="form-control"
                      value={selectedBatch}
                      onChange={handleSelectBatch}
                    >
                      <option value="Batch 1">Batch 1</option>
                      <option value="Batch 2">Batch 2</option>
                      <option value="Batch 3">Batch 3</option>
                      <option value="Batch 4">Batch 4</option>
                      <option value="Batch 5">Batch 5</option>
                      <option value="Batch 6">Batch 6</option>
                      <option value="Batch 7">Batch 7</option>
                      <option value="Batch 8">Batch 8</option>
                      <option value="Batch 9">Batch 9</option>
                      <option value="Batch 10">Batch 10</option>
                      <option value="Batch 11">Batch 11</option>
                      <option value="Batch 12">Batch 12</option>
                      <option value="Batch 13">Batch 13</option>
                      <option value="Batch 14">Batch 14</option>
                      <option value="Batch 15">Batch 15</option>
                      <option value="Batch 16">Batch 16</option>

                    </select>
                  </div>
                </div>

                {/* <div className="col-xl-6 col-xxl-6 col-6">

                <div className="form-check custom-checkbox mb-3 checkbox-success">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`customCheckBox1`}
                    // onChange={() =>
                    // handleCheckboxChangeOffline(data.user_app_id)
                    // }
                    checked={true}
                  />
                  <label className="form-check-label" htmlFor="customCheckBox2">
                    Has Class Running Notes
                  </label>
                </div>
                </div> */}

                <br></br>
               

                {isLoading ? (
                  <div className="text-center">
                    <div
                      className="spinner-border text-primary text-center"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(AddClass);
