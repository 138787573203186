import React, { useState, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { COLUMNS } from "./Columns"; // Import the columns configuration
import "./filtering.css";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from "react-perfect-scrollbar";
import swal from "sweetalert";
import { connect, useDispatch, useSelector } from "react-redux";

// import { addCommentAction } from "../../store/actions/AuthActions";
import {
  addCommentAction,
  getLeadCommentsAction,
} from "../../../../../store/actions/OMS/Actions";


const FilteringTable = ({ data, reduxCurrentState }) => {
  const columns = useMemo(() => COLUMNS, []);

  const [isModelShown, setIsModelShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [leadUuid, setLeadUuid] = useState("");
  const [comment, setComment] = useState("");
  const colors = ["primary", "success", "warning", "dark"];

  const dispatch = useDispatch();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 7 }, // Customize initial pagination state
    },
    useFilters, // Enable column-based filtering
    useGlobalFilter, // Enable global search filter
    usePagination // Enable pagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const history = useHistory();
  const handleClick = () => {
    history.push("/new-transaction");
  };

  const handleWhatsAppClick = (number) => {
    const whatsappURL = `https://wa.me/+91${number}`;

    window.open(whatsappURL, "_blank"); // Opens in a new tab
  };

  const onAddComment = () => {
    if (!comment) {
      swal("Oops", "Empty Comment Found", "error");
      return;
    }

    setIsLoading(true);

    dispatch(
      addCommentAction(leadUuid, comment, reduxCurrentState.one.webAuthTokenId)
    )
      .then(() => {
        setComment("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error adding comment:", error);
        setIsLoading(false);
      });
  };

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const renderModelUpdate = () => {
    return (
      <Modal className="fade bd-example-modal-lg" show={isModelShown} size="lg">
        <Modal.Header>
          <Modal.Title>Comments</Modal.Title>
          <Button
            onClick={() => setIsModelShown(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-xl-12 col-xxl-12 col-lg-12">
            <div className="card">
              {reduxCurrentState.two.currentLeadComments.length > 0 && (
                <div className="card-body">
                  <PerfectScrollbar
                    style={{ height: "300px" }}
                    id="DZ_W_TimeLine"
                    className="widget-timeline dlab-scroll height370 ps ps--active-y"
                  >
                    {reduxCurrentState.two.currentLeadComments.length > 0 && (
                      <ul className="timeline">
                        {reduxCurrentState.two.currentLeadComments.map(
                          (comment, index) => {
                            // Dynamically choose the color class based on the index
                            const colorClass = colors[index % colors.length];

                            return (
                              <li key={index}>
                                {" "}
                                <div
                                  className={`timeline-badge ${colorClass}`}
                                ></div>
                                <Link className="timeline-panel text-muted">
                                  <span>
                                    {comment.created_at || "Just now"}
                                  </span>{" "}
                                  <h6 className="mb-0">
                                    {comment.comment ||
                                      "No comment text available"}
                                  </h6>
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </PerfectScrollbar>
                </div>
              )}

              <div className="form-group mb-3">
                <textarea
                  id="email-compose-editor"
                  className="textarea_editor form-control bg-transparent"
                  rows="2"
                  placeholder="Enter text ..."
                  value={comment} // Bind state to the textarea value
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>

        {isLoading ? (
          <Modal.Footer>
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button onClick={() => setIsModelShown(false)} variant="dark light">
              Close
            </Button>
            <Button onClick={() => onAddComment()} variant="primary">
              Add Comment
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };

  const onClickUpdates = (leadId) => {
    setLeadUuid(leadId);

    dispatch(
      getLeadCommentsAction(leadId, reduxCurrentState.one.webAuthTokenId)
    )
      .then(() => {
        setIsModelShown(true);
      })
      .catch((error) => {
        console.error("Error adding comment:", error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <Button className="me-2" variant="primary" onClick={handleClick}>
              <span className="btn-icon-start text-dark">
                <i className="fa fa-plus" />
              </span>
              {" "} Add Transaction
            </Button>
            <div className="input-group search-area w-auto">
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                      fill="var(--secondary)"
                    ></path>
                  </svg>
                </Link>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                style={{ width: "20%" }}
              />
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table {...getTableProps()} className="table dataTable display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                  {page.map((row) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          console.log("cell data", cell.row.original.uuid);

                          return (
                            <td {...cell.getCellProps()}>
                              
                                {cell.render("Cell")}
                              
                            </td>
                          );
                        })}

                       

                        <td>
                          <button
                            onClick={() => onClickUpdates(row.original.uuid)}
                            type="button"
                            className="btn btn-primary light px-3"
                          >
                            <i class="fa-solid fa-pen-to-square"></i>
                          </button>
                        </td>

                        {/* <td>
                          <DropDownBlog />
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
              </div>
              <div className="text-center mb-3">
                <div className="filter-pagination mt-3">
                  <button
                    className="previous-button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>
                  <button
                    className="previous-button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <button
                    className="next-button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <button
                    className="next-button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderModelUpdate()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(FilteringTable);
