import { format } from 'date-fns';
import { ColumnFilter } from './ColumnFilter';

export const COLUMNS = [

  
  {
    Header: 'Category',
    Footer: 'Category',
    accessor: 'category',
    Filter: ColumnFilter,
  },
  {
    Header: 'Title',
    Footer: 'Title',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: 'TxnDate',
    Footer: 'TxnDate',
    accessor: 'txn_date',
    Filter: ColumnFilter,
  },
  {
    Header: 'Amount',
    Footer: 'Amount',
    accessor: 'amount',
    Filter: ColumnFilter,
  },
  
];
