import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
} from "../../services/AuthService";
import swal from "sweetalert";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const REGISTER_CONFIRMED_ACTION = "[register action] confirmed register";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const GET_USERS_ACTION = "GET_USERS";
export const GET_CLASSES_ACTION = "GET_CLASSES";
export const GET_LEADERBOARD_ACTION = "GET_LEADERBOARD";
export const GET_MY_PORTFOLIO_ACTION = "GET_MY_PORTFOLIO";
export const GET_PROJECTS_ACTION = "GET_PROJECTS";
export const GET_TREATS_ACTION = "GET_TREATS";
export const GET_ORDERTREAT_ACTION = "GET_ORDERTREAT";
export const GET_NEXTCLASS_ACTION = "GET_NEXTCLASS";
export const GET_CHALLENGES_ACTION = "GET_CHALLENGES";
export const GET_MCQ_ACTION = "GET_MCQ";
export const GET_MYACCOUNT_DETAILS_ACTION = "GET_MYACCOUNT_DETAILS";
export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const GET_NOTIFICATIONS_ACTION = "GET_NOTIFICATIONS";
export const GET_MOCKTESTS_ACTION = "GET_MOCKTESTS";
export const GET_RESUMES_ACTION = "GET_RESUMES";
export const GET_DATASETS_ACTION = "GET_DATASETS";
export const SAVE_MY_UPIID_ACTION = "SAVE_MY_UPIID";
export const GET_ASSIGNMENTS_ACTION = "GET_ASSIGNMENTS";

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export const toggleDarkMode = (isDarkMode) => {
  return {
    type: TOGGLE_DARK_MODE,
    payload: isDarkMode,
  };
};

export function getDatasets(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getdatasets",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          console.log("getdatasets = ", responseJson);
          dispatch(getDatasetsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getAssignments(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getassignments",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          console.log("getassignments = ", responseJson);
          dispatch(getAssignmentsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getResumes(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getresumes",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          console.log("resumes = ", responseJson);
          dispatch(getResumesAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function viewNotifications(webAuthTokenId, notificationId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "viewnotifications",
        web_auth_token_id: webAuthTokenId,
        notification_id: notificationId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          console.log("do nothing");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getNotifications(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getnotifications",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getNotificationsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getUsers() {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "admin_users",
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getUsersAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getMockTests(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getmocktests",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getMockTestsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getLeaderboard(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getleaderboard",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getLeaderboardAction(responseJson));
          console.log("getleaderboard response", responseJson);
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getNextClass(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getnextclass",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getNextClassAction(responseJson));
          console.log("getnextclass response", responseJson);
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getMyAccountDetails(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getmyaccountdetails",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getMyAccountDetailsAction(responseJson));
          console.log("get challenges response", responseJson);
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function saveMyUPIID(webAuthTokenId, upiId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "savemyupiid",
        web_auth_token_id: webAuthTokenId,
        upiId: upiId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(saveMyUPIIDAction(responseJson));
          swal("Done", "Cool, Your UPI ID has been saved.", "success");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function editMyUsername(webAuthTokenId, newUsername) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "editusername",
        web_auth_token_id: webAuthTokenId,
        new_username: newUsername,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getMyAccountDetailsAction(responseJson));
          swal("Done", "Username changed, Please reload the page.", "success");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function editPfp(webAuthTokenId, image) {
  console.log("image ", image);
  const data = JSON.stringify({
    image: image,
  });

  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "sfsfd",
        web_auth_token_id: webAuthTokenId,
        new_username: image,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getMyAccountDetailsAction(responseJson));
          swal("Done", "Username changed, Please reload the page.", "success");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}
export function getChallenges(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getchallenges",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getChallengesAction(responseJson));
          console.log("get challenges response", responseJson);
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getClasses(webAuthTokenId) {
  console.log("autho token", webAuthTokenId);

  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getclasses",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getClassesAction(responseJson));
          console.log("get classes response", responseJson);
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to usdddd.");
      });
}

export function getMyPortfolio(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getmyportfolio",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          console.log("my portfolio = ", responseJson);
          dispatch(getMyPortfolioAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getProjects(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getstudentprojects",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          console.log("projects = ", responseJson);
          dispatch(getProjectsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getTreats(webAuthTokenId) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "gettreats",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getTreatsAction(responseJson));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function orderTreat(webAuthTokenId, itemUuid) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "ordertreat",
        web_auth_token_id: webAuthTokenId,
        item_uuid: itemUuid,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getOrderTreatAction(responseJson));
          swal("Done", "Order Placed Successfully.", "success");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function changePasswordAction(
  cPassword,
  newPassword,
  webAuthTokenId,
  history
) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "changepassword",
        web_auth_token_id: webAuthTokenId,
        old_pwd: cPassword,
        new_pwd: newPassword,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          swal("Done", responseJson["message"], "success");
          dispatch(logout(history));
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function loginAction(email, password, history) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "login",
        email: email,
        pwd: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          saveTokenInLocalStorage(responseJson);
          dispatch(loginConfirmedAction(responseJson));

          if (responseJson["role"] == "Counselor") {
            history.push("/dashboard");
          }
          else if (responseJson["role"] == "Trainer") {
            history.push("/save-recording");
          } else {
            history.push("/classes");
          }

        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.");
      });
}

export function getUsersAction(data) {
  return {
    type: GET_USERS_ACTION,
    payload: data,
  };
}

export function getMockTestsAction(data) {
  return {
    type: GET_MOCKTESTS_ACTION,
    payload: data,
  };
}

export function getNotificationsAction(data) {
  return {
    type: GET_NOTIFICATIONS_ACTION,
    payload: data,
  };
}

export function getMCQAction(data) {
  return {
    type: GET_MCQ_ACTION,
    payload: data,
  };
}

export function getChallengesAction(data) {
  return {
    type: GET_CHALLENGES_ACTION,
    payload: data,
  };
}

export function getAssignmentsAction(data) {
  return {
    type: GET_ASSIGNMENTS_ACTION,
    payload: data,
  };
}
export function getMyAccountDetailsAction(data) {
  return {
    type: GET_MYACCOUNT_DETAILS_ACTION,
    payload: data,
  };
}

export function saveMyUPIIDAction(data) {
  return {
    type: SAVE_MY_UPIID_ACTION,
    payload: data,
  };
}

export function getClassesAction(data) {
  return {
    type: GET_CLASSES_ACTION,
    payload: data,
  };
}

export function getLeaderboardAction(data) {
  return {
    type: GET_LEADERBOARD_ACTION,
    payload: data,
  };
}

export function getNextClassAction(data) {
  return {
    type: GET_NEXTCLASS_ACTION,
    payload: data,
  };
}

export function getMyPortfolioAction(data) {
  return {
    type: GET_MY_PORTFOLIO_ACTION,
    payload: data,
  };
}

export function getProjectsAction(data) {
  return {
    type: GET_PROJECTS_ACTION,
    payload: data,
  };
}

export function getDatasetsAction(data) {
  return {
    type: GET_DATASETS_ACTION,
    payload: data,
  };
}

export function getResumesAction(data) {
  return {
    type: GET_RESUMES_ACTION,
    payload: data,
  };
}

export function getTreatsAction(data) {
  return {
    type: GET_TREATS_ACTION,
    payload: data,
  };
}

export function getOrderTreatAction(data) {
  return {
    type: GET_ORDERTREAT_ACTION,
    payload: data,
  };
}

export function registerUserAction(
  webAuthTokenId,
  name,
  email,
  password,
  track,
  batch,
  history
) {
  return (dispatch) =>
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "register",
        web_auth_token_id: webAuthTokenId,
        name: name,
        email: email,
        pwd: password,
        track: track,
        batch: batch,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(registerConfirmedAction(responseJson));
          swal("Good job!", "Student has been registered. ", "success");
          history.push("/users");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.", error);
      });
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function registerConfirmedAction(data) {
  return {
    type: REGISTER_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
